import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Launch from './components/pages/Launch';
import Navbar from './components/layout/Navbar';
import React, { Suspense, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import Login from './components/common/Login';
import Popups from './components/common/Popups';
const Token = React.lazy(() => import('./components/pages/Token'));

function App() {
  
  return (
    <div className="App">
    <BrowserRouter>
    <Popups />
    <Login />
      <Navbar  />
      <Routes>
        <Route path="/" element={<Home  />} />
        <Route path="/launch" element={<Launch />} />
        <Route
            path="/token/:tokenAddress"
            element={
              <Suspense fallback={<div>Loading token data...</div>}>
                <Token />
              </Suspense>
            }
          />      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
