import { Link } from "react-router-dom";
import React from "react";
import TokensFeed from "../TokensFeed";
import { useEffect, useState } from "react";

function Navbar() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://etherfun.pro/api2/latest?page=1"); // Replace with your API
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setData(data);
        // console.log(data); // Removed console.log
      } catch (error) {
        setError(error);
        // console.log(error); // Removed console.log
      }
    };

    fetchData(); // Call the fetch function
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div className="flex gap-2  pb-[0px] border-b-[1px] border-white/10 w-full overflow-x-hidden">
      <div className="pl-4 pt-3 w-full ">
        <ul className="flex space-x-4 text-white w-[240px] ">
          <li>
            <Link to="/" className=" hover:underline">
              <img
                className="w-7 h-7  flex items-center"
                src="/ethervista.png"
                alt="Etherfun"
              />
            </Link>
          </li>
          <li>
            <Link
              to="/launch"
              className=" hover:underline text-transparent bg-clip-text bg-gradient-to-r from-yellow-500   to-blue-500 font-bold "
            >
              [Launch]
            </Link>
          </li>
          <li>
            <a
              href="https://docs.ethervista.app/etherfun"
              target="_blank"
              className=" hover:underline"
              rel="noreferrer"
            >
              [How it works]
            </a>
          </li>
        </ul>
      </div>
      <div class="w-full">
        <TokensFeed data={data} class="w-full" />
      </div>
    </div>
  );
}

export default Navbar;
