import React from "react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";

function Login() {
  const [providerSet, setProvider] = useState(null);
  const [signerSet, setSigner] = useState(null);
  const [error, setError] = useState(null); // State to handle the error message

  const initializeProvider = async () => {
    try {
      let provider;
      let signer;
      if (window.ethereum == null) {
        setError(null);
        setError("Metamask not detected. Please Install Metamask");
        // console.log("MetaMask not installed; using read-only defaults"); // Removed console.log
        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
      }
      await setProvider(provider);
      await setSigner(signer);
    } catch (e) {}
  };
  useEffect(() => {
    initializeProvider();
  }, []);

  return (
    <div>
      {signerSet ? (
        <>
          <button
            class="z-50 bg-gray-500/50 text-bold py-1 text-sm hover:bg-gray-400/50 px-8 absolute right-12 rounded-full"
            style={{ top: "70px" }}
          >
            {signerSet.address.slice(0, 7) +
              "..." +
              signerSet.address.slice(-5)}
          </button>
        </>
      ) : (
        <button
          class="z-50 bg-gray-500/50 text-bold py-1 text-md hover:bg-gray-400/50 px-8 absolute right-10 rounded-full"
          style={{ top: "70px" }}
          onClick={initializeProvider}
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
}

export default Login;
